
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$wsguias-frontend-primary: mat.define-palette(mat.$indigo-palette);
$wsguias-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$wsguias-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$wsguias-frontend-theme: mat.define-light-theme((
  color: (
    primary: $wsguias-frontend-primary,
    accent: $wsguias-frontend-accent,
    warn: $wsguias-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($wsguias-frontend-theme);

/* Global General Styles */

@import 'src/public/styles/colors.scss';
@import 'src/public/styles/spacings.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &::selection {
    color: $snow;
    background-color: $orange-mecanique;
  }
}

html {
  height: stretch;
  height: -webkit-stretch;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

body, input, textarea, select, button {
  font: 400 1rem "Roboto", sans-serif;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Screen & Menu Retraction Styles */

._retractable-screen {
  padding: 2.5rem 5rem;
  display: block;
}

._collapsed-menu {
  &--desktop {
    position: absolute;
    right: 0;
    width: calc(100% - $collapsed-menu-width) !important;

    transition: width 0.3s;
    transition-delay: 0.15s;
  }

  &--mobile {
    margin-top: $mobile-menu-height;
    width: 100%;
    padding: 2rem 2rem !important;
  }
}

._expanded-menu {
  &--desktop {
    position: absolute;
    right: 0;
    width: calc(100% - $expanded-menu-width) !important;

    transition: width 0.3s;
  }
}

/* Grid Template */

.grid-row {
  width: 100%;
  display: grid;

  &._col-1 {
    grid-template-columns: 1fr;
  }

  &._col-2 {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }

  & + .grid-row {
    margin-top: 1rem;
  }
}

/* Form input wrapper */

.form {
  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

/* Form Input Validation Message */

.validation-message {
  margin-top: 0.5rem;
  display: block;
  font-weight: 500;
  font-size: 0.8rem;

  &._success {
    color: $neighbor-grass;
  }

  &._error {
    color: $dracula-blood;
  }

  &._info {
    color: $pacific-ocean;
  }
}

/* Popover styles */

.dialog {
  width: 100%;
  padding: 1rem;
}

::ng-deep {
  .mat-dialog-content {
    margin: 0;
    padding: 0;
  }
}

.dialog-title {
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 1.25rem;
    padding: 0;
    font-weight: bold;
    font-size: 1.15rem;
  }

  &:after {
    content: '';
    align-self: center;
    display: block;
    width: 100%;
    height: 1px;
    background-color: $cloudy-sky;
    border-radius: 0.25rem;
  }
}

.dialog-body {
  width: 100%;
  margin-top: 1.25rem !important;
  font-size: 1rem;
  font-weight: 300;
}

.dialog-footer {
  width: 100%;
  margin-top: 2rem;
  display: flex;

  button {
    & + button {
      margin-left: 0.5rem;
    }
  }
}

/* SnackBar Styles */

.custom-snackbar {
  color: $snow;
  font-weight: bold;

  button {
    color: $snow;
    font-weight: 400;
  }

  &._success {
    background: $neighbor-grass;
  }

  &._error {
    background: $dracula-blood;
  }

  &._info {
    background: $pacific-ocean;
  }
}

/* Badge styles */

.status-badge {
  width: 100%;
  display: block;
  padding: 0.25rem 0;
  border-radius: 1rem;
  color: $snow;
  font-weight: bold;
  text-align: center;
}

.status-error {
  background: #DC0A0A;
}

.status-success {
  background: #49A238;
}

.status-processing {
  background: #6933FF;
}

.status-queued {
  background: #969CB3;
}

.pdf-download {
 border: 0;
 outline: none;
 background-color: transparent;
 color: #DC0A0A;

 &:disabled {
  color: #A8A8B3;
  cursor: not-allowed;
 }
}
