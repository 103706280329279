/* Colors */
$orange-mecanique: #E1742E;
$sunset: #F8B629;
$pacific-ocean: #0277BD;
$dracula-blood: #DC0A0A;
$neighbor-grass: #49A238;
$night-fury: #0C0C0C;
$octopus-ink: #29292E;
$snow: #FFFFFF;
$dirty-snow: #F5F5F5;
$mystic-fog: #EEEEEE;
$cloudy-sky: #D6D6D6;
$moon-surface: #969696;
